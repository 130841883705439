import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  FeatureImage,
  FeatureImageHero,
  FeatureImageHeroMobile,
  Paragraph,
  Container,
  H2
} from "../components/styled/components"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import { useMediaQuery } from "react-responsive"

const FAQsPage = ({ data }) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })

  return (
    <Layout>
      <SEO title="FAQs | WellHealth" />
      {isTabletOrMobile ?
      <FeatureImageHeroMobile>
        <H2 fontSize="40px" textAlign="center">
          Frequently Asked Questions
        </H2>
        <Img fluid={data.photo1.childImageSharp.fluid} alt="FAQs"/>
      </FeatureImageHeroMobile> :
      <FeatureImageHero>
        <section>
          <h2>
            Frequently Asked Questions
          </h2>
        </section>
        <FeatureImage>
          <Img fluid={data.photo1.childImageSharp.fluid} alt="FAQs"/>
        </FeatureImage>
      </FeatureImageHero>}
      <Container pb={5}>
        <Accordion allowZeroExpanded={true}>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                How is COVID-19 spread?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Paragraph mb={5}>
                SARS-CoV-2 (COVID-19) spreads easily from person-to-person, even when an infected person 
                is not showing symptoms. When an infected person coughs, sneezes, or talks, droplets containing 
                the virus go into the air. These droplets can be inhaled or land in the mouths or noses of people 
                who are nearby, exposing them to the virus. People may also be exposed to COVID-19 by touching their 
                eyes, nose, or mouth after touching a surface with the virus on it. Although this is not thought to be
                the main way the virus spreads, researchers are still learning more about COVID-19.
              </Paragraph>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                What are the symptoms of COVID-19?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Paragraph mb={5}>
                Symptoms include fever, loss of smell, coughing, sore throat, shortness of breath or difficulty breathing, 
                feeling weak or lethargic, chills, muscle pain, lightheadedness or dizziness, headache, vomiting or diarrhea, 
                slurred speech, and/or seizures.<br /><br />
                The Centers for Disease Control and Prevention (CDC) offers many resources on COVID-19, including symptoms.
                <a style={{ color: "blue" }} href="https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/symptoms.html"> Click here </a>
                to look them up.
              </Paragraph>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                How do I get my COVID-19 test results?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Paragraph mb={5}>
                Your testing results will be provided to you through your registration link. You will be notified via email and text of your results.
              </Paragraph>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Who is the COVID-19 molecular (PCR) test appropriate for?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Paragraph mb={5}>
                This test may be helpful if you:<br />
                <ul>
                  <li>
                    Currently have COVID-19 symptoms including fever, cough, shortness of breath, and/or loss of taste or smell. Other less common symptoms may include tiredness, body or muscle aches, headache, nausea, vomiting, diarrhea, sore throat, and/or runny or stuffy nose
                  </li>
                  <li>
                    Live or work in a place where people reside, meet, or gather in close proximity (this can include healthcare settings, homeless shelters, assisted living facilities, group homes, prisons, detention centers, schools, and workplaces)
                  </li>
                  <li>
                    Have been in close contact with someone who has a confirmed case of COVID-19
                  </li>
                  <li>
                    Your employer, public health department, contact investigator, or healthcare provider has identified you as someone who should get tested
                  </li>
                  <li>
                    You are visiting a place that requires testing prior to arrival
                  </li>
                </ul>
              </Paragraph>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                What will a COVID-19 test tell me?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Paragraph mb={5}>
                Test results will confirm whether or not you are positive or negative for COVID-19.<br /><br />
                If your results are positive, it is important to contact your healthcare provider immediately, self-isolate, and monitor your symptoms.<br /><br />
                If your results are negative, you were most likely not infected with COVID-19 at the time of sample collection. However, it is important to note that in some cases there is not enough SARS-CoV-2 RNA or antigen in the sample to detect the virus (false negative). This can happen in a certain percentage of cases during the stages of infection. If you are experiencing symptoms, it is important to follow up with a healthcare provider for further evaluation, and to take protective measures to avoid infecting others. Negative results do not mean that you are not at risk for contracting or spreading COVID-19.<br /><br />
                It is important that you discuss your test results with your doctor and follow public health guidance about safety precautions.<br /><br />
              </Paragraph>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Is rapid testing available?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Paragraph mb={5}>
                Yes
              </Paragraph>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                How is a rapid test different from a PCR test? 
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Paragraph mb={5}>
                Rapid antigen tests (sometimes known as a rapid diagnostic test – RDT) detect viral proteins 
                (known as antigens). Samples are collected from the nose and/or throat with a swab. These tests 
                are cheaper than PCR and will offer results more quickly, although they are generally less accurate. 
                These tests perform best when there is more virus circulating in the community and when sampled from 
                an individual during the time they are most infectious.<br /><br />
                <a style={{ color: "blue" }} href="https://www.who.int/docs/default-source/coronaviruse/tile-2_use-of-antigen-detection.pdf?sfvrsn=f7b4df40_4">Learn more about rapid diagnostic test.</a>
              </Paragraph>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                How long will it take to get my COVID-19 test results? 
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Paragraph mb={5}>
                Positive patients receive a phone call from a provider the same day that their samples are resulted. Patients receive negative results through an automated system within seconds that the lab report posts. PCR Patients can receive results following authentication via text, email, or integrated voice response.<br /><br />
                Lab reports are made available for patients to download through the portal when desired.<br /><br />
              </Paragraph>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Why do I need to be tested if I don’t have symptoms?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Paragraph mb={5}>
                Even if you don't have symptoms, you should get tested 3-5 days after close contact with 
                someone who has COVID-19 exposure or if required by your workplace, as this has been shown
                to reduce the risk of spread.
              </Paragraph>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Why do I need to be tested if I have been vaccinated?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Paragraph mb={5}>
                You should still get tested if you’ve had close contact with someone who has COVID-19 or 
                if you have symptoms of COVID-19.   If you’ve had close contact with someone who has COVID-19, 
                you should get tested 5-7 days after your exposure, even if you don’t have symptoms. You should 
                also wear a mask indoors in public for 14 days following exposure or until your test result is negative. 
                If you have symptoms of COVID-19, you should get tested and stay home and away from others.
              </Paragraph>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </Container>
    </Layout>
  )  
}

export default FAQsPage

export const query = graphql`
  query FAQsPageQuery {
    photo1: file(relativePath: { eq: "about_page_hero.png" }) {
      childImageSharp {
        fluid(maxHeight: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
